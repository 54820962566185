a {
  color: #4451a3;
}

a:hover {
  color: #0050e6;
}

p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;
  color: #262626;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}
.pointer {
  cursor: pointer;
}
.btn-primary {
  background: #4451a3;
  height: 54px;
  padding: 15px 64px 15px 16px;
  border-radius: 0;
  border: 0;
}

.btn-primary:hover {
  background: #0050e6;
}

.text-primary {
  color: #4451a3 !important;
}

a.text-primary:hover {
  color: #0050e6 !important;
}

.text-body {
  color: #262626 !important;
}

.dropdown-menu {
  box-shadow: 0px 2px 6px 0px #0000004d;
  border-radius: 0;
}

.dropdown-menu li {
  font-size: 14px;
}

.dropdown-menu li a {
  padding: 10px;
}
.client-setup h1 {
  font-size: 42px;
  line-height: 1;
  font-weight: 500;
}
.client-setup p.text {
  font-size: 28px;
  line-height: 36px;
  color: #525252;
}
.client-setup-steps {
  border-radius: 7px;
  border: 1px solid #4451a380;
  background: #eceef680;
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  max-width: 145px;
}

.client-setup-steps .small {
  color: #525252;
  font-size: 14px;
}
.client-setup-steps .step-name {
  color: #161616;
  font-size: 14px;
  font-weight: 500;
}
.msg-from-firm-sec {
  background: #4451a31a;
}
.firm-msg {
  background: #fff;
  padding: 20px;
  height: 535px;
  overflow-y: auto;
}
.firm-msg p {
  font-size: 16px;
  line-height: 26px;
}
.dark-bg-logo {
  background: #4451a3;
  padding: 8px;
  border-radius: 3px;
}

.link {
  color: var(--Default-Light-Blue-60, #6289b0);
  text-decoration: none;
}
.frontend-footer,
.frontend-footer a {
  color: #525252;
  font-size: 12px;
}

.cstm-progress .cds--progress-step {
  inline-size: 14rem;
  min-inline-size: 13rem;
}
.cstm-progress .cds--progress-step-button {
  inline-size: 100%;
}
.cstm-progress .cds--progress-text {
  width: 100%;
}
.cstm-progress .cds--progress-label {
  max-inline-size: 15rem;
}
.cstm-progress .cds--progress-line {
  inline-size: 15rem;
}
.cstm-progress .cds--progress-step--complete .cds--progress-label {
  font-weight: 500;
}
.cstm-progress .cds--progress-step--complete .cds--progress-line {
  inline-size: 14rem;
  background-color: #4451a3;
}

.cstm-progress .cds--progress-step--current .cds--progress-line {
  background-color: var(--cds-border-subtle);
}

.requestedFilesCompleted {
  background: #eceef680;
  border-radius: 7px;
  max-width: 280px;
}
.requestedFilesCompleted h3 {
  color: #4451a3;
  font-size: 33px;
  font-weight: 500;
}
.cstm-top-heading h6 {
  color: #161616;
  font-size: 20px;
  font-weight: 500;
}
.cstm-top-heading p {
  color: #525252;
  font-size: 14px;
}
.cloud-file-uploader {
  border: 2px dotted #4451a3;
  border-radius: 4px;
  max-width: 450px;
  min-height: 400px;
}
.cloud-file-uploader .cds--form-item {
  align-items: center;
  display: flex;
}
.questioner-sec {
  background-color: #eee8f7;
}
.questioner.first {
  border-top: 15px solid #5c33ae;
}
.questioner h4 {
  font-size: 17px;
  font-weight: 500;
}
.questioner .cds--select-input,
.questioner .cds--text-input {
  background-color: #fff;
}

/* ************* Override CSS code START from here  ************* */
.cds--data-table-header,
.cds--table-toolbar {
  background-color: var(--layer-02);
}

.cds--data-table--zebra tbody tr.cds--data-table--selected {
  background: #d7e6f5 !important;
}
.cds--data-table--zebra tbody tr {
  background-color: #fff;
}
.cds--data-table--zebra
  tbody
  tr:not(.cds--parent-row):not(.cds--data-table--selected):nth-child(even) {
  background-color: #f4f4f4;
}

.cds--tag.grey-outline {
  outline: 1px solid #8d8d8d;
  color: #8d8d8d;
}
.cds--tag.green {
  background: #d3e2ce;
}

.cds--modal-header {
  padding-block-end: 1rem;
}
.cds--modal-content > p,
.order-checkbox-list .cds--checkbox-label-text {
  font-size: 16px;
}
.two-buttons .cds--modal-footer {
  padding-left: 30%;
}
.cds--modal .cds--modal-footer,
.cds--modal .cds--modal-footer .cds--btn {
  block-size: 3.5rem;
}
.multistep-form .cds--modal-content {
  padding-block-end: 0;
  padding-block-start: 0.5rem;
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.field-type-button button:nth-child(2) {
  background-color: #bbacac !important; /* Light gray */
}

.multistep-form .cds--modal-footer .cds--btn {
  padding-block-end: 0;
}
.multistep-form .cds--file-container {
  width: 100%;
}
.multistep-form .cds--file__selected-file {
  max-inline-size: 100%;
  background: #fff;
}
.multistep-form .cds--form-item button[aria-describedby='description'] {
  background-image: url(../public/images/Cloud--upload.png);
  background-repeat: no-repeat;
  background-position: right 8px top 15px;
}
.cds--btn--primary.dark-blue,
.cds--form-item .cds--btn--primary {
  background-color: #4451a3;
}
.cds--btn--primary.dark-blue:hover,
.cds--form-item .cds--btn--primary:hover {
  background-color: #0050e6;
}

.cds--btn--tertiary {
  border-color: #4451a3;
  color: #4451a3;
}
.cds--btn--tertiary:hover {
  background-color: #4451a3;
}

.cds--checkbox:checked + .cds--checkbox-label::before,
.cds--checkbox:indeterminate + .cds--checkbox-label::before,
.cds--checkbox-label[data-contained-checkbox-state='true']::before {
  border: none;
  border-width: 1px;
  background-color: #4451a3;
}
.light-blue .cds--combo-button__primary-action button,
.light-blue button.cds--combo-button__trigger {
  background-color: #7aabdc;
  color: #000;
}
.no-mask-img .cds--modal-content {
  mask-image: none;
  padding-bottom: 0;
}
.cds--toast-notification__subtitle {
  padding-top: 15px;
}

.plan-item .cds--contained-list-item__content {
  font-size: 13px;
  padding: 8px;
  min-block-size: auto;
}

.lang-switcher .cds--list-box__field {
  padding-inline-end: 33px;
  padding-inline-start: 8px;
}
.lang-switcher .cds--dropdown--inline .cds--list-box__menu {
  min-inline-size: 6rem;
}

.lang-switcher.white .cds--list-box__label {
  color: #fff;
}
.lang-switcher.white .cds--list-box__menu-icon > svg {
  fill: #fff;
}
.lang-switcher.white .cds--list-box__field:hover .cds--list-box__label {
  color: #000;
}
.lang-switcher.white .cds--list-box__field:hover .cds--list-box__menu-icon > svg {
  fill: #000;
}

.cds--overflow-menu-options--sm .cds--overflow-menu-options__option {
  block-size: 3rem;
  background-color: #fff;
}
.cds--overflow-menu-options--sm .cds--overflow-menu-options__option:hover {
  background-color: #e8e8e8;
}

.cds--combo-button__container .cds--btn--primary,
.cds--modal .cds--modal-footer .cds--btn--primary {
  background-color: var(--prince-50);
}

.cds--combo-button__container:hover .cds--btn--primary,
.cds--modal .cds--modal-footer .cds--btn--primary:hover {
  background-color: var(--prince-70);
}

.cds--combo-button__container.btn--primary {
  background-color: #fff;
}
.cds--table-toolbar .cds--btn-set .cds--btn {
  inline-size: max-content;
  max-inline-size: fit-content;
}

.header-border .cds--modal-header {
  border-bottom: 1px solid #e0e0e0;
}

.cds--data-table--sm tbody td.cds--table-expand {
  padding-inline-start: 0;
}

.cds--data-table--zebra
  tbody
  tr:not(.cds--parent-row):not(.cds--data-table--selected):nth-child(even)
  .cds--parent-row
  .cds--table-expand {
  background-color: #f4f4f4 !important;
  border-block-end: 0 !important;
  border-block-start: 0 !important;
}
.cds--data-table--zebra
  tbody
  tr:not(.cds--parent-row):not(.cds--data-table--selected):nth-child(even):hover
  .cds--parent-row
  .cds--table-expand {
  background-color: #f4f4f4 !important;
}
.cds--data-table--zebra
  tbody
  tr:not(.cds--parent-row):not(.cds--data-table--selected):nth-child(odd)
  .cds--parent-row
  .cds--table-expand {
  background-color: #fff !important;
  border-block-end: 0 !important;
  border-block-start: 0 !important;
}
.cds--data-table--zebra
  tbody
  tr:not(.cds--parent-row):not(.cds--data-table--selected):nth-child(odd):hover
  .cds--parent-row
  .cds--table-expand {
  background-color: #f4f4f4 !important;
}

.cds--data-table--zebra tbody tr:not(.cds--parent-row):not(.cds--data-table--selected):hover {
  background-color: transparent;
}

.cds--data-table--zebra tbody tr[data-parent-row] td,
.cds--data-table--zebra tbody tr[data-child-row] td {
  background-color: transparent !important;
}
tbody:has(> tr.cds--data-table--slug-row) > tr.cds--expandable-row[data-child-row] td,
tbody:has(td.cds--table-column-checkbox) > tr.cds--expandable-row[data-child-row] td {
  padding-inline-start: 0;
  padding-inline-end: 0;
}
.expandable-table.cds--data-table--sm td,
.expandable-table.cds--data-table--sm tbody tr th,
.expandable-table.cds--data-table--sm.cds--data-table--top-aligned-header
  th.cds--table-column-checkbox {
  padding-block-end: 2px;
  padding-block-start: 2px;
}
.expandable-table tbody tr > td:nth-child(2),
.expandable-table tbody tr > td:nth-child(3) {
  padding-inline-start: 0rem;
  padding-inline-end: 0rem;
}

.expandable-table thead tr > th:nth-child(3) .cds--table-header-label {
  padding-inline-start: 0 !important;
}

.hide-contained-list__header .cds--contained-list__header {
  display: none;
}
.fullWidth .cds--file__selected-file {
  max-inline-size: 38rem;
  background-color: #fff;
}

.small-dropdown .cds--list-box__field {
  padding-inline-end: 25px;
  padding-inline-start: 5px;
}
.small-dropdown .cds--list-box__menu-icon {
  inset-inline-end: 2px;
}

.react-tel-input .form-control {
  border-left: 1px solid #e0e0e0 !important;
  border-right: 0px !important;
  border-top: 0px !important;
  height: 41px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

.flag-dropdown {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 1px solid #e0e0e0 !important;
  background-color: #fff !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

.invert .flag-dropdown {
  background-color: var(--cds-field) !important;
  border-bottom: 1px solid var(--cds-border-strong) !important;
}
.invert .react-tel-input .selected-flag:hover,
.invert .react-tel-input .selected-flag:focus {
  background-color: var(--cds-field) !important;
  border-bottom: 1px solid var(--cds-border-strong) !important;
}
.invert .react-tel-input .form-control {
  background-color: var(--cds-field);
  border-bottom: 1px solid var(--cds-border-strong) !important;
}
.invert .react-tel-input .form-control {
  width: 100%;
}

@media (min-width: 82rem) {
  .add-client-modal .cds--modal-container {
    inline-size: 60%;
  }
}

/* ************* Override CSS code END here  ************* */

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #ffffff; /* White background for clean look */
  border-bottom: 1px solid #e0e0e0; /* Light grey border for subtle separation */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow for modern depth effect */
}

.header-button {
  background-color: #4451a3; /* Primary color */
  color: #ffffff; /* Text color */
  border: none;
  border-radius: 25px; /* Rounded corners for modern look */
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transitions for hover effects */
}

.cds--modal-header__heading {
  padding-inline-end: unset !important;
}
/* .header-sign .cds--tooltip-trigger__wrapper {
    display: none;
} */

.header-button:hover {
  background-color: #4451a3; /* Darker shade on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.header-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(0, 114, 193, 0.4); /* Custom focus outline */
}
