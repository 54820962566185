:root {
  --font-family: 'IBM Plex Sans', sans-serif;
  --font-weight: 400;
  --letter-spacing: 0.16px;

  --prince-10: #eceef6;
  --prince-20: #c7cbe3;
  --prince-30: #8f97c8;
  --prince-40: #707bbd;
  --prince-50: #4451a3;
  --prince-60: #364182;
  --prince-70: #293162;
  --prince-80: #1b2041;
  --prince-90: #0e1021;

  --Default-Light-Blue-50: #7aabdc;
  --Default-Gray-90: #262626;
  --Default-Gray-60: #6f6f6f;
  --Text-text-primary: #161616;
}

/* Prince Palatte color theme */
.prince-10 {
  color: var(--prince-10);
}

.prince-20 {
  color: var(--prince-20);
}

.prince-30 {
  color: var(--prince-30);
}

.prince-40 {
  color: var(--prince-40);
}

.prince-50 {
  color: var(--prince-50);
}

.prince-60 {
  color: var(--prince-60);
}

.prince-70 {
  color: var(--prince-70);
}

.prince-80 {
  color: var(--prince-80);
}

.prince-90 {
  color: var(--prince-90);
}

.gray-60 {
  background-color: var(--Default-Gray-60);
}
.gray-60:hover {
  background-color: var(--prince-70);
}
.text-16 {
  color: var(--Text-text-primary);
}

.body-compact-01 {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--font-weight);
  letter-spacing: var(--letter-spacing);
}

.body-compact-02 {
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0;
}

.body-compact-03 {
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--font-weight);
  letter-spacing: 0;
}

.body-compact-04 {
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--font-weight);
  letter-spacing: 0;
}

.body-compact-05 {
  font-family: var(--font-family);
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--font-weight);
  letter-spacing: 0;
}

.body-compact-06 {
  font-family: var(--font-family);
  font-size: 32px;
  line-height: 40px;
  font-weight: var(--font-weight);
  letter-spacing: 0;
}

.body-compact-07 {
  font-family: var(--font-family);
  font-size: 40px;
  line-height: 48px;
  font-weight: var(--font-weight);
  letter-spacing: 0;
}

.body-compact-08 {
  font-family: var(--font-family);
  font-size: 48px;
  line-height: 56px;
  font-weight: var(--font-weight);
  letter-spacing: 0;
}

.body-compact-09 {
  font-family: var(--font-family);
  font-size: 64px;
  line-height: 72px;
  font-weight: var(--font-weight);
  letter-spacing: 0;
}

.cds-heading-01,
.cds-heading-02,
.cds-heading-03,
.cds-heading-04,
.cds-heading-05,
.cds-heading-06,
.cds-heading-07 {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  letter-spacing: var(--letter-spacing);
}

.cds-heading-01 {
  font-size: 2.25rem;
  /* 36px */
  line-height: 1.1111;
}

.cds-heading-02 {
  font-size: 2rem;
  /* 32px */
  line-height: 1.125;
}

.cds-heading-03 {
  font-size: 1.75rem;
  /* 28px */
  line-height: 1.1429;
}

.cds-heading-04 {
  font-size: 1.5rem;
  /* 24px */
  line-height: 1.1667;
}

.cds-heading-05 {
  font-size: 1.25rem;
  /* 20px */
  line-height: 1.2;
}

.cds-heading-06 {
  font-size: 1rem;
  /* 16px */
  line-height: 1.25;
}

.cds-heading-07 {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.2857;
}

.btn--primary {
  background-color: var(--prince-50);
}
.btn--primary:hover {
  background-color: var(--prince-70);
}

.light-blue {
  background: var(--Default-Light-Blue-50);
  color: var(--Default-Gray-90);
}
.light-blue:hover {
  background: var(--prince-70);
  color: #fff;
}

.btn-outline-primary {
  --bs-btn-color: #4451a3;
  --bs-btn-border-color: #4451a3;
  --bs-btn-hover-bg: #4451a3;
  --bs-btn-hover-border-color: #4451a3;
  --bs-btn-active-bg: #4451a3;
  --bs-btn-active-border-color: #4451a3;
  --bs-btn-disabled-color: #4451a3;
  --bs-btn-disabled-border-color: #4451a3;
}
